const colorPalettes = {
    'lightBlue': ['#E3FDFD', '#CBF1F5', '#A6E3E9', '#71C9CE'],
    'sand': ['#fdf5e2', '#fdf1cb', '#fbe5ba', '#fddcb4', '#ffd6a4'],
    'jewel': ['#05878a',
        '#074e67',
        '#5a175d',
        '#67074e',
        '#dd9933',
    ],
    'shadesOfTeal': [
        '#b2d8d8',
        '#66b2b2',
        '#008080',
        '#006666',
        '#004c4c',
    ],
    'pinkPuff': [
        '#F9F5F6',
        '#F8E8EE',
        '#FDCEDF',
        '#F2BED1',
    ],
    'pastelDream': [
        '#6096B4',
        '#93BFCF',
        '#BDCDD6',
        '#EEE9DA',
    ],
};


function pickTextColorBasedOnBgColorAdvanced(bgColor, lightColor, darkColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.179) ? darkColor : lightColor;
};

const getPaletteColor = (paletteName, index) => {
    const palette = colorPalettes[paletteName];
    const bg = palette[index % palette.length];
    const fg = pickTextColorBasedOnBgColorAdvanced(bg, '#FFFFFF', '#000000');
    return {
        'background': bg,
        'foreground': fg,
    }

};

export {
    getPaletteColor,
    colorPalettes,
};