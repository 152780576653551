export const preMadeText = `#Creating a LinkedIn carousel
is a fantastic way to share information in a visually engaging manner 🎯

##1. Tell a Story
Structure your carousel to narrate a story or take the viewer on a journey through your content.

##2. Keep Text Concise
Minimize the use of text and keep it readable. Use headings and short sentences to convey your message effectively.

##3. Maintain Consistency
Keep the design elements consistent across all the slides for a professional and unified appearance.

##4. Add Value 
💡 Offer valuable insights, tips, or information that would benefit your audience and make them want to engage with your carousel.

##5. Avoid Overloading Slides
Keep each slide simple and focused on one idea or concept to avoid overwhelming the viewer.

##6. Finally: Include a CTA
Encourage engagement by including a clear call to action, like visiting your website or contacting you directly.
##Link in the comments 👇`;
