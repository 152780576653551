import React, { useRef, useState, useEffect } from 'react';
import {generateJSXMeshGradient} from 'meshgrad';
import generatePdf from "./helpers/generatePdf";
import createCarousel from "./helpers/createCarousel";
import { preMadeText } from './lib/preMadeText';
import { colorPalettes, getPaletteColor } from './lib/colorPalettes';
import { SwatchIcon, ArrowPathIcon } from '@heroicons/react/24/solid';

const CarouselGenerator = () => {
    const pages = useRef();
    const textArea = useRef();

    const meshStyle = generateJSXMeshGradient(8);
    const [background, setBackground] = useState(meshStyle);
    const [text, setText] = useState(preMadeText);
    const [carousel, setCarousel] = useState([]);
    const [bgPalette, setBgPalette] = useState('pastelDream');
    const [bgMeshMode, setbgMeshMode] = useState(false);

    useEffect(() => {
        setCarousel(createCarousel(text));
    }, [text]);

    const generatePdfHandle = async () => {
        await generatePdf([...pages.current.querySelectorAll('.item')]);
    }

    const changeBackground = () => {
        setbgMeshMode(true);
        setBackground(generateJSXMeshGradient(Math.floor(Math.random()*5)+1));
    }

    const handleTextChange = (event) => {
        setText(event.target.value);
    }


    const handlePaletteChange = (event) => {
        setbgMeshMode(false);
        setBgPalette(event.target.innerText);
    }

    return (
        <div className="container mx-auto p-4">
            <section className="py-10 mb-4">
                <div className="max-w-4xl mx-auto text-center">
                    <h1 className="text-4xl font-bold mb-4">LinkedIn Carousel Generator</h1>
                    <p className="text-lg">Create stunning carousel posts for LinkedIn with ease!</p>
                </div>
            </section>

            <div className="flex flex-row  justify-between mb-3">
                <div className="preview-label">
                    <h2 className="text-2xl font-bold mb-4 inline-block mb-0">Preview your carousel 😎</h2>

                </div>
                <button className="btn btn-sm m-1 btn-primary" onClick={generatePdfHandle}>Generate PDF</button>
                <div className="flex flex-row align-right">
                
                    <div className="dropdown dropdown-hover text-xs">
                        <label tabIndex={0} className="btn btn-sm m-1 text-xs">
                        <SwatchIcon className="h-6 w-6 text-blue-500" />
                        Change color scheme</label>
                        <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                            { Object.keys(colorPalettes).map(function(item, index) {
                                return (
                                    <li key={index}><a className={ item == bgPalette ? 'font-extrabold' : ''} value={item} onClick={handlePaletteChange}>{item}</a></li>
                                );
                            })}
                        </ul>
                    </div>
                    <button className="btn btn-sm m-1 text-xs" onClick={changeBackground}>
                        <ArrowPathIcon className="h-6 w-6 text-blue-500" />
                        Generate mesh background
                    </button>
                </div>
            </div>

            <div ref={pages} className="flex overflow-auto hover:overflow-scroll flex-nowrap justify-between shrink-0 w-full">
                { carousel.map((item, index) => {
                    return (
                        <div className="mr-3 border-solid border-2 border-indigo-600" key={index}>
                            <div className="item">
                               <div style={bgMeshMode ? background : {
                                    backgroundColor: getPaletteColor(bgPalette, index).background,
                                    color: getPaletteColor(bgPalette, index).foreground,
                                }} className="w-96 h-96 bg-blue-500 text-dark p-4 aspect-square" dangerouslySetInnerHTML={{ __html: item }} />
                            </div>
                        </div>
                    )
                }) }
            </div>
            <div>
             <p className="text-lg text-center m-3">
                <ul className="steps">
                    <li className="step step-primary">Change content below</li>
                    <li className="step step-primary">Chage the colors at the top right</li>
                    <li className="step">Click 'Generate PDF'</li>
                    <li className="step">Enjoy your amazing carousel!</li>
                </ul>
             </p>
            </div>

            <div className="text-editor my-4">
                <label htmlFor="message" className="block mb-2 font-medium text-gray-800">Your Content</label>
                <textarea
                    ref={textArea}
                    id="message"
                    className="border-2 block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 h-96"
                    placeholder="Write your content here..."
                    value={text}
                    onChange={handleTextChange}
                ></textarea>
            </div>

            <div className="mt-4 text-center">
                <button className="btn btn-primary" onClick={generatePdfHandle}>Generate PDF</button>
            </div>
        </div>
    );
};

export default CarouselGenerator;
